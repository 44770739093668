.gallery {
	
	&_grid {
		display: grid;
		grid-template-columns: 1fr 1fr;

		li:last-child {
			display: none;
		}
	}

	@include breakpoint(phablet) {

		&_grid {
			grid-template-columns: repeat(3, 1fr);

			li:last-child {
				display: block;
			}			
		}
	}
	@include breakpoint(tablet) {
		
		&_grid {
			grid-template-columns: repeat(5, 1fr);
		}
	}
}