.clientes {
	padding: 30px;

	h2 {
		font-weight: 800;
		font-size: 26px;
		margin-bottom: 36px;
		display: inline-block;
		border-bottom: 2px $blue solid;
		padding-bottom: 8px;
	}	

	.grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
		margin-top: 42px;

		.center {
			align-self: center;
		}
	}

	@include breakpoint(phablet) {
		text-align: center;

		h2 {
			margin-bottom: 32px;

		}
		
		p {
			font-size: 20px;
		}
		
		.grid {
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 30px;
		}
	}
	@include breakpoint(tablet) {
		padding: 60px;

	}
	@include breakpoint(laptop) {
		padding:  90px 14vw;

		h2 {
			font-size: 32px;
		}

		.grid {
			max-width: 960px;
			margin: 52px auto 0;
			grid-gap: 60px;
		}
	}
}