.articles {
	padding: 30px;
	background: url('../img/articles-bg-m.jpg') no-repeat top center;
	background-size: cover;

	picture {
		margin-bottom: 22px;
	}

	p {
		margin-bottom: 16px;

		&:last-child {
			margin: 0;
		}
	}

	.article {
		margin-bottom: 42px;
	}

	@include breakpoint (phablet) { 
		display: flex;
		justify-content: center;

		.article {
			flex: 48% 0 0;
			margin-left: 10px;

			&:first-child {
				margin: 0 10px 0 0;
			}
		}
	}

	@include breakpoint (tablet) { 
		padding: 60px;
		background-image: url('../img/articles-bg.jpg');
	}

	@include breakpoint (laptop) { 
		font-size:  18px;

		.article {
			flex: 440px 0 0;
			margin-left: 20px;

			&:first-child {
				margin: 0 20px 0 0;
			}
		}
	}

	@include breakpoint (desktop) { 
		background-image: url('../img/articles-bg-h.jpg');

		.article {
			flex: 540px 0 0;
		}		
	}
}