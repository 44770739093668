.details {
	padding: 50px 30px;

	h2 {
		color: $lightblue;
		font-weight: 800;
		font-size: 26px;
		margin-bottom: 16px;

		&.andaluz {
			background: url('../img/transportes-andaluz.jpg') no-repeat;
			background-size: 60px;
			padding-top: 82px;
		}
	}

	h3 {
		color: $lightblue;
		font-weight: 400;
		font-size: 20px;
		margin-bottom: 16px;
	}
	
	p {
		font-size: 20px;
		margin-bottom: 18px;

		&:last-of-type {
			margin: 0;
		}
	}

	.items {
		margin: 32px 0;
		text-align: left;
		font-weight: 600;

		li {
			background: url('../img/li-check-dark.svg') no-repeat left 6px;
			background-size: 24px;
			padding-left: 40px;
			margin-bottom: 18px;
			line-height: 32px;
			font-weight: 400;

			&:last-child {
				margin: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}		
	}

	&.tecno {
		background: $lightblue;

		.items {

			li {
				background: url('../img/details-icon-cctv.svg') no-repeat left 6px;
				background-size: 32px 32px;
				padding-left: 60px;
				min-height: 42px;

				&:nth-child(1) {
					background-size: 28px;
				}
				&:nth-child(2) {
					background-image: url('../img/details-icon-extintores.svg');
				}
				&:nth-child(3) {
					background-image: url('../img/details-icon-infrarrojo.svg');
				}
				&:nth-child(4) {
					background-image: url('../img/details-icon-anillo.svg');
				}
				&:nth-child(5) {
					background-image: url('../img/details-icon-alarma.svg');
				}
				&:nth-child(6) {
					background-image: url('../img/details-icon-plagas.svg');
				}
				&:nth-child(7) {
					background-image: url('../img/details-icon-seguridad.svg');
				}
				&:nth-child(8) {
					background-image: url('../img/details-icon-humo.svg');
				}
				&:nth-child(9) {
					background-image: url('../img/details-icon-humo.svg');
				}
			}
		}
	}

	&.color {
		background: $lightblue;

		+ .interna {
			padding-top: 30px;
		}
	}

	&.values {
		background: url('../img/values-m.jpg') no-repeat;
	}

	@include breakpoint (phablet) { 
		padding: 60px;

		.items {
			margin: 48px auto;
		}

		&.color {

			+ .interna {
				padding-top: 60px;
			}
		}		
	}

	@include breakpoint (tablet) { 
		
		h2 {
			font-size: 28px;
		}

		.items {
			display: grid;
			grid-template-columns: repeat(2,1fr);
			grid-gap: 30px;
			max-width: none;
			margin: 48px auto;

			li {
				margin: 0;
			}
		}		

		&.tecno {

			.items {		
				display: grid;
				grid-template-columns: repeat(2,1fr);
				grid-gap: 30px;
				max-width: none;
				margin: 48px auto 0;

				li {
					margin: 0;
				}
			}
		}

		&.color {

			.items {
				display: block;

				li {
					margin-bottom: 26px;
				}				
			}
		}		

		&.values {
			background: url('../img/values-h.jpg') no-repeat;
		}	
	}

	@include breakpoint (laptop) { 
		padding: 60px 14vw;

		h2 {
			font-size: 30px;
	
			&.andaluz {
				background-size: 80px;
				padding-top: 102px;
			}
		}
		
		p {
			font-size: 22px;
		}
	}
}