*, *:before, *:after { box-sizing: border-box; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
img {
	display: block;
	width: 100%;
}
h1, h2, h3, h4, h5, h6 {
	// font-family: $font-base;
	font-weight: 600;
	line-height: 1.4;
}
ul, li {
	list-style: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, picture, svg {
	display: block;
}
body {
	font: 400 16px $font-base;
	color: $blue;
	line-height: 1;
	background: $white;
	position: relative;
}
a {
	text-decoration: none;
	transition: all .4s ease;
	color: $black;
}
p {
	line-height: 1.4;
}
strong {
	font-weight: 600;
}
input, textarea, select {
	width: 100%;	
	background: none;
	padding: 14px 6px;
	margin-bottom: 8px;
	border: none;
	border-bottom:  2px $white solid;
	color: $white;
	font-family: $font-base;
	font-size: 14px;
	transition: all .2s ease-in-out;
	
	&::placeholder {
		color: $white;
	}	
	&::-moz-placeholder {
	  opacity: 1;
	}
	&:focus {
		border-bottom-color: $lightblue;

		&::placeholder {
			color: $lightblue;
		}
	}
	&.error {
		border-color: red;
	}
}
a.button {
	overflow: hidden;
	padding: 0;
	width: 100%;
	border: 2px solid;
	border-color: $white;
	border-radius: 30px;
	font-size: 16px;	
	font-weight: 600;
	background: transparent;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	cursor: pointer;

	&:before, & > span {
		padding: .6em 1em;
		transition: transform 0.3s;
		transition-timing-function: ease;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}    
	
	& > span {
	    display: block;
	    vertical-align: middle;
	    color: $white;
	}

	&:before {
		color: $blue;
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $white;
		transform: translate3d(-100%, 0, 0);	    

	}

	&:hover {

		&:before {
		    transform: translate3d(0, 0, 0);
		}
		& > span {
			transform: translate3d(0, 100%, 0);
		}
	}
}

input.button {
	padding: 12px 18px;
	background: $lightblue;
	color: $white;
	border: 2px $lightblue solid;
	border-radius: 20px;

	&:hover {
		background: transparent;
	}
}

select {
	color: $blue;

	option {
		color: $blue;
	}
}

a.button { 
	display: block;
	text-align: center;
}

.grecaptcha-badge {
	opacity: 0;
}

.swiper-container {

	.swiper-button {
		background: url('../img/slide-arrow.svg') no-repeat center;
		width: 40px;
		height: 40px;
		background-size: contain;
		box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
		border-radius: 20px;	
		left: 0;

		&-next {
			transform: rotate(.5turn);
			right: 0;
			left: auto;
		}
	}
}

.whatsapp-button {
	width: 48px;
	height: 48px;
	position: fixed;
	bottom: 30px;
	left: 30px;
	z-index: 30;
}

.jquery-modal {
	z-index: 13;

	.modal {
		padding: 20px;
		border-radius: 0;
		max-width: 80%;

		&.gallery {
			padding: 0;
			max-width: 90%;
		}
		
		&.work {
			padding: 32px;
			text-align: center;
			max-width: 660px;	

			h2 {
				font-size: 24px;
				font-weight: 800;
				text-decoration: underline;
				margin-bottom: 22px;
			}

			.button {
				background-color: $lightblue;
				border-color: $lightblue;
				width: 180px;
				margin: 22px auto 0;
			}	
		}
	}

	@include breakpoint(tablet) {

		.modal {

			&.gallery {
				max-width: none;
				width: auto;

				img {
					width: auto;
					height: 80%;
				}
			}
		}
	}

	@include breakpoint(laptop) {
		
		.modal {
			max-width: 65%;
			z-index: 14;

			iframe {
				height: 70vh;
			}
		}
	}
}