.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: absolute;
	z-index: 10;
	padding: 20px;
	background:  linear-gradient( to left, $blue, $lightblue);

	&__toggle {
		width: 27px;
	}

	&__toggle-line {
		display: block;
		background: $white;
		width: 100%;
		height: 4px;
		margin-bottom: 4px;
		opacity: 1; 
		transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms;    
		
		&:nth-child(1) {
			transform-origin: center;
		}
		&:nth-child(3) {
			transform-origin: center;
		}
	}    

	&.is-active {
		position: fixed;
		z-index: 11;

		.header__toggle-line {

			&:nth-child(1) {
				transform: translateY(8px) rotate(45deg);
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				transform: translateY(-8px) rotate(-45deg);
			}
		}	
	}

	&.fixed {
		top: 0;
		position: fixed;
		z-index: 11;
		animation: headerFix .5s ease-out;	
	}
  			
	@include breakpoint(tablet) {
		padding: 20px 40px;
		
	}

	@include breakpoint(laptop) {
		padding:  20px 60px;
		background:  linear-gradient( to left, $blue, $lightblue);		

		.logo {
			
			img {
				height: 50px;
			}
		}

		&__toggle {
		    display: none;
		} 		

		&.fixed {
			position: absolute;
		}
	}
}

.menu {
	position: fixed;
	top: 0;
	left: 100%;
	z-index: 11;
	width: 100%;
	height: 100%;
	opacity: 0;
	padding: 160px 30px 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background: $white;
	transition: opacity 400ms ease, left 100ms ease 600ms;

	&.is-visible {
		left: 0;
		opacity: 1;
		transition: left 100ms ease, opacity 400ms ease 200ms;
		overflow: scroll;
	}
	
	.menu-inner {
		overflow-x: scroll;
		margin: 0;
		padding-bottom: 30px;
		
		.menu-list {
			list-style: none;
			margin: 0 0 22px 0;
			padding: 0;

			li {
				border-bottom: 1px $blue solid;

				&:last-child {
					border: none;
				}
		
				> ul {
					border-top: 1px $blue solid;

					li {
						padding-left: 12px;
					}
					
					a {
						font-size: 16px; 
						line-height: 50px;
					}
				}
			}
		}
		
		a:not(.button) {
			font-size: 20px; 
			line-height: 60px;
		}
		
	}

	.button {
		width: 160px;
		background: $lightblue;
		line-height: 1;
	}

	// .gracias & { display: none;}

	@include breakpoint(mobilelandscape) {
		min-height: 100%;
		height: auto;
		overflow-x: scroll;
	}

	@include breakpoint(tablet) {
		padding: 110px 60px 60px;

		.menu-inner {
			width: 320px;
			padding: 0;
		
			a:not(.button) {
				line-height: 48px;
			}
			
			.submenu ul {
				
				a {
					line-height: 42px;
				}
			}
		}
	}

	@include breakpoint(laptop) {
		position: absolute;
		left: 0;
		top: 30px;
		opacity: 1;
		background: none;
		padding: 0 44px 0 0;
		height: auto;
		align-items: flex-end;
		pointer-events: none;
		
		.menu-inner {
			width: auto;
			display: flex;
			align-items: flex-start;
			margin: 0;
			padding: 0;
			overflow-x: visible;
			
			.menu-list {
				display: flex;
				margin: 0;

				li {
					margin: 0;
					border: none;

					a {
						font-size: 18px;   
						line-height: 36px;
						padding: 0 16px;

						&.button {
							line-height: 14px;
						}
					}

					&.submenu {
						position: relative;
					}
		
					> ul {
						border: none;
						display:flex;
						flex-direction:column;				
						visibility:hidden;
						position: absolute;
						left:-99999;
						top: 28px;
						transition: none;
						width: 270px;
						padding-top: 8px;

						&:before {
							content: '';
							width: 0;
							height: 0;
							border-left: 10px solid transparent;
							border-right: 10px solid transparent;
							border-bottom: 8px solid $white;							
							position: absolute;
							top: 0;
							left: 20px;
						}

						li {
							padding: 0;
						}

						a {
							font-size: 14px; 
							line-height: 1;
							color: $grey;
							padding: 20px;
							background: $white;
							border-bottom: 1px $white solid;
							transition: none;

							&:hover {
								border-bottom-color: $grey;
							}
						}
					}

					&.submenu:hover {

						ul {
							visibility: visible;
							left: 0;
						}
					}
				}
			}	

			a {
				display: block;
				padding: 0 8px;
				pointer-events: auto;
				color: $white;

				&:hover {
					color: $lightblue;    
				}
			}
		}  		

		&.fixed {
			top: 0;
			padding: 13px 20px;
			background: $blue url('../img/logo-siasa.svg') no-repeat;   
			background-size: 40px;
			background-position: 40px center;
			position: fixed;
			animation: headerFix .5s ease-out;
			
			.menu-inner {

				a {

					&:hover {
						color: $lightblue;
					}	
				}
			}
		}
	}

}

@keyframes headerFix {
	0%   { top: -100px; }
	100% { top: 0; }
}