.company {

	.content {
		padding: 50px 30px;
	}

	h2 {
		display: inline-block;
		font-size: 20px;
		font-weight: 800;
		border-bottom: 1px $blue solid;
		padding-bottom: 16px;
		margin-bottom: 32px; 
	}

	p {
		margin-bottom: 16px;

		&.arrow {
			position: relative;

			&:before {
				content: '';
				display: inline-block;
				background: url('../img/li-arrow-bold.svg') no-repeat;
				background-size: 20px;
				width: 28px;
				height: 14px;
				position: static;
			}
		}

		&:last-child {
			margin: 0;
		}
	}

	@include breakpoint (phablet) { 

		.content {
			padding: 60px;
		}
	}

	@include breakpoint (tablet) { 
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		.figure {
			
			img {
				object-fit: cover;
				object-position: center;
				height: 460px;
			}

			&:nth-child(3) {
				grid-column: 2 / 3;
				grid-row: 2 / 3;
			}
		}

		.content {
			align-self: center;

			h2 {
				font-size: 28px;
			}

			&:nth-child(4) {
				grid-column: 1 / 2;
				grid-row: 2 / 3;
			}			
		}
	}

	@include breakpoint (laptop) { 

		.figure {
			
			&:nth-child(3) {
				grid-column: 2 / 3;
				grid-row: 2 / 3;
			}
		}

		.content {
			justify-self: start;
			max-width: 540px;

			&:nth-child(4) {
				justify-self: end;
			}			
		}
	}

	@include breakpoint (desktop) { 

		.content {
			max-width: 680px;

		}
	}
}