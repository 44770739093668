.highlight {
	padding: 50px 30px;
	background: $blue;
	color: $white;
	text-align: center;
	font-size: 20px;

	h2 {
		
		&.light {
			font-weight: 400;
		}

		span {
			font-weight: 600;
		}
	}

	h3 {
		display: inline-block;
		font-weight: 800;
		font-size: 22px;
		border-bottom: 2px $white solid;
		padding-bottom: 6px;
		margin-bottom: 16px;	
	}

	p {
		margin-bottom: 12px;
	}

	.logo-center {
		width: 180px;
		margin: 0 auto 38px;
	}

	.button {
		background-color: $lightblue;
		border-color: $lightblue;

		&:last-child {
			margin-top: 22px;
		}
	}	

	&.image {
		background: url('../img/highlight-mission-m.jpg') no-repeat top center;
		background-size: cover;
		text-align: left;

		h2 {
			display: inline-block;
			font-weight: 800;
			font-size: 26px;
			border-bottom: 2px $white solid;
			padding-bottom: 6px;
			margin-bottom: 16px;			
		}
	}

	&.ong {
		background-image: url('../img/highlight-ong-m.jpg');
	}

	&.certificate {
		color: $blue;
		background: $lightblue-bg;

		&.image.white {
			background: white;
			padding-bottom: 60px;
		}

		h2 {
			border-bottom-color: $blue; 
		}

		.items {
			display: block;
			margin-top: 32px;

			li {
				margin-bottom: 16px;
			}

			+ p {
				margin-top: 32px;
			}

			+ h3 {
				margin-top: 42px;
			}
		}

		.downloads {
			margin: 40px auto 0;
			width: 260px;
		}

		.qualifications {
			margin-top: 42px;

			h3 {
				border-color: $blue;
			}
		}
	}

	&.clients {
		background: none;
		color: $blue;

		img {
			height: 60px;
			width: auto;
			margin: 0 auto;
		}

		.clients-slide {
			margin: 60px 0 0 0;
		}
	}

	&.services {
		background: url('../img/highlight-almacenamiento-m.jpg') no-repeat center;
		background-size: cover;

		h2 {
			text-shadow: 0 0 8px $black;
		}

		strong {
			display: block;
		}
	}

	@include breakpoint (phablet) { 
		padding: 60px;
		
	}

	@include breakpoint (tablet) { 
		
		h2 {
			font-size: 28px;
		}

		&.image {
			background-image: url('../img/highlight-mission.jpg');		
			background-attachment: fixed;
		}

		&.ong {
			background-image: url('../img/highlight-ong.jpg');
		}

		&.image.certificate {
			background-image: none;
			background: $lightblue-bg;
		}		

		&.image.clients {
			background: none;		
		}

		&.services {
			background-image: url('../img/highlight-almacenamiento.jpg');
			background-attachment: fixed;
			padding: 120px 0;
		}	

		&.certificate {

			.downloads {
				margin: 40px auto 0;
				width: 520px;
				display: flex;

				.button {
					margin: 0 16px;
				}
			}	
		}
	}

	@include breakpoint (laptop) { 
		padding: 60px 14vw;

		.logo-center {
			width: 220px;
		}

		&.image {

			h2 {
				font-size: 36px;
			}

			p {
				font-size: 22px;
			}
		}
	}

	@include breakpoint (desktop) { 

		&.image {
			background-image: url('../img/highlight-mission-h.jpg');
		}

		&.ong {
			background-image: url('../img/highlight-ong-h.jpg');
		}

		&.services {
			background-image: url('../img/highlight-almacenamiento-h.jpg');
		}

	}
}