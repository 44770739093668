.sections {
	position: relative;
	padding: 30px;

	h2 {
		color: $lightblue;
		font-weight: 800;
		font-size: 26px;
		margin-bottom: 36px;
		display: inline-block;
		border-bottom: 2px $lightblue solid;
		padding-bottom: 8px;
	}
	
	&_post {
		color: $grey;
		padding: 0;
		margin-bottom: 32px;
		box-shadow: 0px 0px 8px $grey;
	
		picture {
			display: block;		
			
			img { transition: all 1.6s ease-in-out; }
		}

		header {
			padding: 30px 20px;
		}

		h3 {
			display: inline-block;
			font-weight: 800;
			font-size: 20px;
			border-bottom: 2px #939393 solid;
			padding-bottom: 6px;
			margin-bottom: 16px;
		}

		p {
			margin-bottom: 12px;
		}

		&:last-child { 
			margin: 0;
		}
	}

	&_link {
		display: block;	

		&:hover {

			img {
				transform: scale(1.1);
			}
		}			
	}

	&_mas {
		width: 120px;
		display: flex;
		justify-content: space-between;
		margin-top: 16px;
		font-weight: bold;

		.icon {
			fill: $blue;
			width: 8px;
			transition: all 0.4s;
		}
	}

	&.interna {
		padding-top: 0;
	}

	.servicios-slide {

		.swiper-slide {

			.sections_link {
				position: relative;
				overflow: hidden;

				h3 {
					font-size: 22px;
					color: $white;
					text-transform: uppercase;
					text-align: center;
					position: absolute;
					top: 50%;
					width: 100%;
					transform: translateY(-50%);
					z-index: 2;
					padding: 0;
				}

				img { 
					transition: all .6s ease-out; 
				}

				&:before {
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					background: rgba(0,0,0,.6);
					z-index: 1;
				}
			}
		}

		.swiper-button {
			left: 6px;

			&-next {
				right: 6px;
				left: auto;
			}
		}		
	}

	@include breakpoint(phablet) {

		h2 {
			margin-bottom: 32px;
		}
		
		&_grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
		}

		&_post {
			margin: 0;		
		}

		&.interna {
			padding: 0 60px 60px;
		}
	}
	@include breakpoint(tablet) {
		padding: 60px;

		&_grid {
			max-width: 1360px;
			margin: 0 auto;
		}

		&_post {
			
			h3 {
				margin-bottom: 12px;
			}
		}	

		.servicios-slide .swiper-button {
			display: block;
			left: 10px;

			&-next {
				right: 10px;
				left: auto;
			}
		}	

		.sections_link {

			h3 {
				padding: 0 60px;			
			}
		}
	}
	@include breakpoint(laptop) {
		
		&_post {
			overflow: hidden;
			
			header {
				padding: 25px;
				height:  100%;
				z-index:  1;
				background: $white;
				position:  relative;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left:  0;
					width: 100%;
					height: 100%;
					transition: all .3s ease;
					background: linear-gradient( to right, $lightblue, $blue);
					opacity: 0;
					transition: opacity 0.4s;
					z-index: -1;
				}
			}
			
			h3 {
				font-size: 32px;
				transition: all 0.3s;
			}

			p {
				transition: all 0.3s;
			}

			header {

				&:hover {

					h3, p {
						color: $white;
					}

					h3 {
						border-bottom-color: $white;
					}


					&:before {
						opacity: 1;
					}
				}
			}
		}

		&_mas {

			&:hover {
				color: $white;

				.icon {
					fill:  $white;
				}
			}
		}

		&.interna {
			padding: 0 14vw 90px;
		}
	}
}

.text-layout {
	padding: 140px 30px 50px 30px;
	color: $blue;
	
	h1 {
		font-size: 28px;
		margin: 20px 0;
	}
	h2 {
		font-size: 24px;
		margin: 32px 0 20px 0;
	}
	h3 {
		font-size: 20px;
		margin: 20px 0;
	}
	p {
		margin-bottom: 12px;
	}
	ul {
		padding-left: 18px;

		li {
			list-style: disc;
		}
	}

	@include breakpoint(phablet) {
		padding: 140px 60px 60px 60px;
		max-width: 1320px;
    	margin: 0 auto;
	}
	
}