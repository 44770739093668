.hero {
	background-image: 
		linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
		url('../img/hero-m.jpg'); 
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	display: flex;
	padding: 100px 30px 30px;

	h1 {
		font-weight: 800;
		font-size: 24px;
		line-height: 1.2;
		color: $white;
		text-align: center;
		text-shadow: 1px 1px 3px $black;
		margin: auto;
   		animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

		span {
			font-weight: 400;
		}
	}	

	h2 {
		font-size: 32px;
		font-weight: 700;
		text-align: center;
		margin-bottom: 36px;

		span {
			font-size: 20px;
			font-weight: normal;
			display: block;
		}
	}

	.video {

		.icon {
			width: 48px;
			fill: $white;
			transition: all .3s ease;
		}

		&:hover {
		
			.icon {
				fill: $lightblue;
			}
		}
	}

	picture {
		width: 100%;
	}

	&.home {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		h1 {
			margin: 0 0 32px 0;
		}
	}

	&.us {
		background-image: 
			linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
			url('../img/hero-us-m.jpg'); 
	}

	&.services {
		background-image: url('../img/hero-services-m.jpg'); 
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;

		h1 {
			margin: 0 auto 42px;
		}

		.button {
			width: 220px;
			animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) .6s both;

			&:first-child {
				position: absolute;
				top: 50%;
				background: $blue;
			}
		}

		+ .highlight {
			background-color: $lightblue;
		}	
	}

	&.almacenamiento {
		background: none;
		height: auto; 
		padding: 100px 0 0 0;
	}

	&.contenedores {
		background: none;
		height: auto; 
		padding: 100px 0 0 0;
	}	

	&.reprocesos {
		background: none;
		height: auto; 
		padding: 100px 0 0 0;
	}	

	&.fiscal {
		background-image: 
		linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.6)),
		url('../img/hero-depositofiscal-m.jpg'); 
		height: 70vh; 
		padding: 100px 0 0 0;
		color: $white;
	}	

	&.transporte {
		background: none;
		height: auto; 
		padding: 100px 0 0 0;
	}	

	&.clients {
		background-image: 
			linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
			url('../img/hero-clientes-m.jpg'); 
	}

	&.acceso {
		background-image: url('../img/hero-acceso-m.jpg'); 
	}

	&.contacto {
		background-image: url('../img/hero-contacto-m.jpg'); 

		h1 {
			color: $blue;
			text-shadow: none;
		}

		+ .highlight {
			background: $white;
			color: $blue;
		}
	}	

	@include breakpoint (phablet) {
		background-image:
			linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
			url('../img/hero.jpg');
		background-position: bottom center;
		height: 50vh;

		h1 {
			font-size: 36px;
			max-width: 70%;
		}

		&.us {
			background-image: 
				linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
				url('../img/hero-us.jpg');

			h1 {
				font-size: 36px;
				text-align: left;
				margin-left: 90px;				
			}
		}		

		&.services {
			background-image: url('../img/hero-services.jpg'); 
		}		
	
		&.almacenamiento, &.contenedores, &.reprocesos, &.transporte {
			background: none; 
		}	

		&.clients {
			background-image: 
				linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
				url('../img/hero-clientes.jpg'); 
		}		

		&.acceso {
			background-image: url('../img/hero-acceso.jpg'); 
			background-position: bottom;
		}		

		&.contacto {
			background-image: url('../img/hero-contacto.jpg'); 
			background-position: top;

			h1 {
				font-size: 36px;
				text-align: left;
				margin-left: 90px;				
			}			
		}	

		&.fiscal {
			background-image: 
			linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
			url('../img/hero-depositofiscal.jpg'); 
			background-position: center;
			padding-top: 102px;
		}
	}
	@include breakpoint (tablet) {
		height: 100vh;

		&.almacenamiento, &.contenedores, &.reprocesos, &.transporte {
			padding: 0;
		}			
	}
	@include breakpoint (laptop) {

		h1 {
			font-size: 42px;
			max-width: 50%;
		}

		h2 {
			font-size: 40px;
		}

		.video {

			.icon {
				width: 68px;
			}
		}		

		&.almacenamiento, &.contenedores, &.reprocesos, &.fiscal, &.transporte {
			height: 85vh;

			picture {
				height: 100%;
				overflow: hidden;

				img {
					object-fit: cover;
					object-position: center;
					height: 100vh;
				}
			}
		}		

		&.clients, &.acceso {

			h1 {
				max-width: none;

				span {
					display: block;
				}
			}
		}

		&.fiscal {
			background-image: 
			linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
			url('../img/hero-depositofiscal.jpg'); 
		}
	}

	@include breakpoint (desktop) {

		h2 {
			font-size: 48px;
		}

		&.us {
			background-image: 
				linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
				url('../img/hero-us-h.jpg'); 	
		}		

		&.services {
			background-image: url('../img/hero-services-h.jpg'); 
		}				
	
		&.almacenamiento, &.contenedores, &.reprocesos, &.transporte {
			background: none;
		}

		&.fiscal {
			background-image: 
			linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
			url('../img/hero-depositofiscal-h.jpg'); 
		}

		&.clients {
			background-image: 
				linear-gradient(to bottom left, rgba(0,0,0,.6), rgba(0,0,0,.1)),
				url('../img/hero-clientes-h.jpg'); 	
		}				

		&.acceso {
			background-image: url('../img/hero-acceso-h.jpg'); 
			background-position: top;
		}				

		&.contacto {
			background-image: url('../img/hero-contacto-h.jpg'); 
			background-position: top;
		}	
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-17 11:30:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}