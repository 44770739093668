.footer {
	padding: 30px;

	.logo {
		width: 180px;
		margin: 0 0 26px 0;
	} 

	.items {
		margin-bottom: 36px;

		a {
			color: $lightblue;
			display: block;
			padding-left: 36px;
			line-height: 22px;
			background: url("../img/footer-maps.svg") no-repeat left 6px;
			background-size: auto;
			background-size: 20px;
			min-height: 28px;

			&.mail {
				background-image: url('../img/footer-mail.svg');
			}
			&.phone {
				background-image: url('../img/footer-phone.svg');
			}
		}

		li {
			margin-bottom: 22px;			
		}
	}

	.social {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;

		p {
			font-weight: 800;
			font-size: 20px;
			color: $lightblue;
			flex: 100% 0 0;
			margin-bottom: 36px;   
		}

		.icon {
			width: 42px;
			height: 42px;
			margin: 0 16px;
			fill: $lightblue;
			transition: all .3s ease;
		}
	}

	&_smile {
		color: $grey;
		text-align: center;
		display: block;
		padding: 18px 0;
		transition: color .3s ease;
		border-top: 1px $grey solid;
		
		&:hover {
			color: $black;
		}
	}

	@include breakpoint(phablet) {

		.social {
			justify-content: flex-start;
			text-align: left;
		}
	}

	@include breakpoint(tablet) {
		padding: 40px 60px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		.logo {
			grid-column:  1 / 4;
		}

		.items {
			grid-column:  1 / 3;
			margin: 0;

			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 12px;

			li {
				margin: 0;
			}

			a {

				&:hover {
					color: $blue;
				}
			}
		}

		.social {
			grid-column:  3 / 4;
			justify-self: end;
			align-content: start;
			margin: 0;

			p {
				margin-bottom: 8px;
			}

			.icon {
				width: auto;
				height: 26px;
				margin: 0 8px;

				&:hover {
					fill: $blue;
				}
			}			
		} 

		&_smile {
			font-size: 14px;
			font-weight: 800;
		}
	}
}