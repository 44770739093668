.vision {
	padding: 50px 30px;

	h2 {
		display: inline-block;
		font-weight: 800;
		font-size: 26px;
		border-bottom: 2px $blue solid;
		padding-bottom: 6px;
		margin-bottom: 16px;			

		+ p {
			font-size: 20px;
		}
	}

	.items {
		margin-top: 32px;
		text-align: center;

		li {
			margin-bottom: 42px;

			&:last-child {
				margin: 0;
			}
		}

		&.no-icon {

			li {
				padding: 0;
				background: none;
			}
		}
	}

	.icon {
		width: 60px;	
		height: 80px;
		margin: 0 auto 22px;
		fill: $blue;
	}

	&.values {
		background: url('../img/values-m.jpg') no-repeat;
	}

	.items {
		text-align: left;
		font-weight: 600;

		li {
			background: url('../img/li-check.svg') no-repeat left 6px;
			background-size: 24px;
			padding-left: 40px;
			margin-bottom: 12px;
			line-height: 32px;
		}

		&.dark {

			li {
				background-image: url('../img/li-check-dark.svg');
			}
		}
	}

	&.sustainability {
		background: url('../img/sustainability-m.jpg') no-repeat;
		background-position: top right;
		background-size: cover;

		.light {
			font-size: 20px;
			color: $lightblue;
			margin: 22px 0;

			a {
				color: $lightblue;
				padding-bottom: 8px;
				border-bottom: 1px $lightblue solid;
				transition: all .3s ease;

				&:hover {
					border-bottom-color: transparent;
				}
			}
		}

		+ .highlight {
			background-color: $lightblue;
		}
	}

	&.acceso {
		background: url('../img/acceso-stock-m.jpg') no-repeat;
		background-position: top right;
		background-size: cover;
		padding: 90px 30px;

		.items {
			margin-bottom: 42px;
		}

		.button {
			border-color: $lightblue;
			background: $lightblue;
			width: 240px;
		}

		+ .highlight {
			background-color: $lightblue;
		}

		&.operation {
			background-image: url('../img/acceso-operation-m.jpg');
			background-position: bottom right;
			background-size: cover;

			.button {
				margin-top: 42px;
			}
		}
	}

	&.contacto {
		background: url('../img/contacto-ubicacion-m.jpg') no-repeat;
		background-size: cover;
		padding: 90px 30px;

		h4 {
			font-size: 20px;
			margin-bottom: 22px;

			&.light {
				font-weight: 400;
			}
		}
		
		p {
			margin-bottom: 18px;

			&:last-child {
				margin: 0;
			}
		}

		a {
			color: $blue;
			border-bottom: 1px $blue solid;
			padding-bottom: 6px;
		}

		iframe {
			margin: 42px 0;
		}
	}

	@include breakpoint (phablet) { 
		padding: 60px;

		.items {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
			margin: 48px auto 0;

			li {
				margin: 0;
			}
		}

		&.sustainability {
			padding-right: 40%;
			background-image: url('../img/sustainability.jpg');
			background-size: contain;
			background-position: 800% top;

			.items {
				display: block;

				li {
					margin-bottom: 20px;
				}
			}			
		}

		&.acceso {
			padding-left: 50%;
			background-image: url('../img/acceso-stock.jpg');
			background-position: -100px top;

			.items {
				display: block;

				li {
					margin-bottom: 20px;
				}
			}			

			&.operation {
				background-image: url('../img/acceso-operation.jpg');
				background-position: 100px bottom;
				padding: 90px 50% 90px 60px;
			}			
		}

		&.contacto {
			padding-right: 50%;
			background-image: url('../img/contacto-ubicacion.jpg');
			background-position: 200px top;			
		}
	}

	@include breakpoint (tablet) { 
		
		h2 {
			font-size: 28px;
		}

		.items {
			grid-gap: 30px;
			max-width: 800px;
			
			&:first-child {
				margin: 0 auto;
			}
		}		

		&.values {
			background: url('../img/values-h.jpg') no-repeat;
		}		

		&.sustainability {
			background-image: url('../img/sustainability.jpg');
			padding-right: 40%;
			background-position: 160%;
		}		

		&.acceso {
			padding: 90px 90px 90px 50%;
			background-size: contain;
			background-position: top left;

			&.operation {
				background-position: bottom right;
				background-size: contain;
				padding: 90px 50% 90px 90px;
			}
		}

		&.contacto {
			padding: 90px 50% 90px 90px;
			background-position: 300px top;

			/*&.operation {
				background-position: bottom right;
				background-size: contain;
				padding: 90px 50% 90px 90px;
			}*/
		}		
	}

	@include breakpoint (laptop) { 
		padding: 60px 14vw;

		h2 {
			font-size: 36px;
	
			+ p {
				font-size: 22px;
			}
		}

		&.sustainability {
			padding-right: 40%;
			background-position: right;
			background-size: contain;
		}		

		&.acceso {
			background-size: 70%;
			background-position: center left;
			padding: 90px 14vw 90px 50%;

			&.operation {
			background-size: 70%;
			background-position: bottom right;
				padding: 90px 60% 90px 14vw;
			}
		}		

		&.contacto {
			padding: 90px 50% 90px 14vw;

			&.operation {
				background-size: 70%;
				background-position: bottom right;
				padding: 90px 60% 90px 14vw;
			}
		}
	}

	@include breakpoint (desktop) { 

		p {
			font-size: 18px;
		}

		.items {
			grid-gap: 60px;
			max-width: 1100px;
		}		

		&.sustainability {
			background-image: url('../img/sustainability-h.jpg');
		}	

		&.acceso {
			padding: 90px 20vw 90px 54%;

			&.operation {
				padding: 90px 54% 90px 20vw;

				h2 {
					max-width: 310px;
				}
			}
		}
	}
}