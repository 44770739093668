.contact {
	background: $blue;
	color: $white;
	padding: 50px 30px 30px;

	h2 {
		font-size: 26px;
		margin-bottom: 22px;
	}

	h3 {
		font-size: 26px;
		font-weight: 400;
		margin-bottom: 22px;
	}

	a {
		color: $lightblue; 
		font-weight: bold;
		border-bottom: 2px $lightblue solid;

		&:hover {
			color: $white;
			border-bottom-color: $white;
		}
	}

	#contactForm {
		margin-bottom: 42px;
		
		.button {
			width: 160px;
			margin: 16px auto 0; 

			&.left {
				margin: 16px 0 0;
				justify-self: start;
			}
		}	
	}

	&.sumate {
		background: $lightblue;

		h2 {
			margin: 0;
		}

		input {

			&:focus {
				border-bottom-color: $blue;

				&::placeholder {
					color: $blue;
				}
			}
		}

		nav {
			margin: 12px 0 0 0;
		}

		.button {
			background: $white;
			color: $lightblue;
			border-color: $white;		
			margin: 0!important;
			cursor: pointer;

			&:hover {
				background: transparent;
				color: $white;
			}

			&.light {
				background: transparent;
				color: $white;
				border-color: $white;		
				margin-bottom: 18px !important;
				max-width: none;

				&:hover {
					background: $white;
					color: $lightblue;
				}		
			}				
		}	
	}

	@include breakpoint(phablet) {
		padding: 60px;

		h2 {
			max-width: 70%;
		}

		#contactForm {
			max-width: 70%;
		}
	}

	@include breakpoint(tablet) {

		h2 {
			font-size: 38px;
			max-width: 580px;
		}

		#contactForm {
			max-width: 680px;
		    display: grid;
			grid-template-columns: 2fr 1fr;
			grid-gap: 20px;
			
			* {margin: 0;} 

			input {
				height: 44px;
			}

			#nombre {        	
				grid-column: 1 / 2;
			}
			#email {        	
				grid-column: 2 / 3;
			}
			#empresa {        	
				grid-column: 1 / 2;
			}
			#telefono {        	
				grid-column: 2 / 3;
			}
			#mensaje {        	
				grid-column: 1 / 3;
			}
			.button {
				grid-column: 1 / 3;
				justify-self: center;
				cursor: pointer;
			}
		}

		&.sumate {
			padding: 60px;
			max-width: 50vw;
			
			h2 {
				max-width: none;
			}

			nav {
				grid-gap: 30px;
				margin-top: 26px;

				.button.light {
					background: transparent url('../img/button-file.svg') no-repeat 16px center;
					background-size: 12px;
					padding-left: 40px;

					&:hover {
						background: $white url('../img/button-file-hover.svg') no-repeat 16px center;
						background-size: 12px;
					}
				}
			}
		}
	}
	
	@include breakpoint(laptop) {
		
		&.sumate {

			nav {
				width: 380px;
			}
		}		
	}
}

.contactoform {

	@include breakpoint(tablet) {
		background: $lightblue url('../img/contacto-sumate.jpg') no-repeat left bottom;
		background-size: contain;
		display: flex;
		justify-content: flex-end;
	}

	@include breakpoint(desktop) {
		background: $lightblue url('../img/contacto-sumate-h.jpg') no-repeat left bottom;
		background-size: 56%;
	}
}